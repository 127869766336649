import React from 'react'

import contentVector from "../../images/content-vector2.svg";
import contentIcon from "../../images/content.svg"
import oneHub from "../../images/oneHub.svg"
import multilingual from "../../images/multilingual.svg"
import secure from "../../images/secure.svg"
import PropTypes from "prop-types";

const ContentManagement = ({lang}) => {
  const content = lang === "de" ? de : en;
    return(
      <section className="bg-blue-100 text-blue-900 pb-32">
        <div className="lg:flex lg:flex-row items-center">
  
          <div className="lg:w-1/2 px-8 md:p-16 md:pl-0">
            <img className="mb-16 mx-auto" src={contentVector} alt=""/>
          </div>
  
          <div className="lg:w-1/2">
            <div className="label text-blue-500">{content.label}</div>
            <h2 id="level-up-your-content">{content.title}</h2>
  
            <div className="lg:flex lg:flex-wrap lg:-mx-2">
              {content.features.map( feature => (
                <div key={feature.title} className="lg:w-1/2 lg:px-2 lg:pb-8">
                  <div className="card border-blue-300">
                    <img className="mb-4" src={feature.icon} alt=""/>
                    <h3 id="all-content-in-one-place">{feature.title}</h3>
                    <p className="text-blue-700">
                      { feature.desc.map(item => <>{item}<br /></>) }
                    </p>
                  </div>
                </div>
              ))}
            </div>
  
          </div>
        </div>
      </section>
    );
  }
ContentManagement.propTypes = { lang: PropTypes.string.isRequired };

const en = {
  label: "Content Management",
  title: "Level up your Content",
  features: [
    {
      title: "All Content in One Place",
      icon: oneHub,
      desc: ["Control all of your content from a single hub.", "Let everybody collaborate on the same basis."]
    },
    {
      title: "Multilingual",
      icon: multilingual,
      desc: ["Reaching an international audience shouldn&#39;t be a nightmare.", "Edit your multilingual content easily."]
    },
    {
      title: "Security",
      icon: secure,
      desc: ["The Content Management System is build with a comprehensive security program."]
    },
    {
      title: "Your Content",
      icon: contentIcon,
      desc: ["Nobody knows better what your content looks like. Create your individual content."]
    },
  ],
}

const de = {
  label: "Content Management",
  title: "Verbessern Sie Ihren Inhalt",
  features: [
    {
      title: "Alle Inhalte an einem Ort",
      icon: oneHub,
      desc: ["Kontrollieren Sie alle Ihre Inhalte von einem einzigen Hub aus.", "Lassen Sie alle auf der gleichen Basis zusammenarbeiten"]
    },
    {
      title: "Multilingual",
      icon: multilingual,
      desc: ["Ein internationales Publikum zu erreichen, sollte kein Alptraum sein.", "Bearbeiten Sie Ihre mehrsprachigen Inhalte mit Leichtigkeit."]
    },
    {
      title: "Sicherheit",
      icon: secure,
      desc: ["Das Content Management System ist mit einem umfassenden Sicherheitsprogramm aufgebaut."]
    },
    {
      title: "Ihr Content",
      icon: contentIcon,
      desc: ["Niemand weiß besser, wie Ihr Inhalt aussieht. Erstellen Sie Ihren Content, nach Ihren Vorstellungen."]
    },
  ],
}

export default ContentManagement
