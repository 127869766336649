import React from 'react'
import PropTypes from "prop-types";

const CallToAction = ({lang}) => {

  const content = lang === "de" ? de : en;
  return(
    <section className="bg-blue-200 text-white">
      <div className="lg:flex">
        <div className="md:w-3/4 mx-auto lg:flex items-center bg-blue-500 p-8 rounded-lg shadow-lg -mt-32">
          <div className="lg:w-1/2">
            <span className="label text-blue-300">{content.label}</span>
            <h2 id="start-working-">{content.title}</h2>
            <p className="text-blue-200">{content.desc}</p>
          </div>
          <div className="lg:pl-8 lg:w-1/2">
            <form className="lg:flex" name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="contact" />
              <input className="block w-full lg:rounded-r-none rounded-lg py-4 px-4 text-blue-500 bg-blue-100 mb-4 lg:mb-0" placeholder="Email" type="text" name="email"/>
              <button className="block px-8 py-4 w-full lg:w-auto lg:rounded-l-none rounded-lg bg-blue-300 text-blue-900" type="submit">{content.btn}</button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

CallToAction.propTypes = { lang: PropTypes.string.isRequired };

const en = {
  label: "contact",
  title: "Start working?",
  desc: "Fill in the form and let's start creating something special.",
  btn: "Send"
}

const de = {
  label: "Kontakt",
  title: "Mit der Arbeit beginnen?",
  desc: "Füllen Sie das Formular aus und lassen Sie uns damit beginnen, etwas Besonderes zu schaffen.",
  btn: "Senden"
}

export default CallToAction;
