import React from 'react'
import speed from "../../images/speed1.svg"
import content from "../../images/content.svg"

import PropTypes from "prop-types";

const Why = ({ lang }) => {

	const content = lang === "de" ? de : en;

    return(
        <section className="bg-blue-100 text-blue-900">
        <div className="md:flex md:flex-row-reverse">

            <div className="py-4 md:w-1/2">
            <div className="md:px-8 mb-8 md:ml-8">
                <div className="label text-blue-500">{content.label}</div>
                <h2 id="why-paperplane-pages">{ content.headline }</h2>
                <p className="text-blue-700 border-l-4 border-blue-300 pl-4">
									{ content.desc.map(item => <>{item}<br /></>) }
								</p>
            </div>
            </div>

            <div className="md:w-1/2">

						{content.features.map(feature => 
							(<div key={feature.title} className="py-4 border-blue-300">
                <img className="mb-4" src={feature.icon} alt=""/>
                <h3 id="performance">{feature.title}</h3>
                <p className="text-blue-700 border-l-4 border-blue-300 pl-4">
									{feature.desc.map(item => {
										return (
											<>
												{item}<br />
											</>
										);
									}
									)}
								</p>
            </div>))}
            </div>

        </div>
        </section>
    );
}

const en = {
	label: "static pages",
	headline: "Why Paperplane Pages?",
	desc: [
		"The Internet of today is slow",
		"And time precious.",
		"Paperplane Pages are the next step of webpages.",
		"Simple, faster and more secure."
	],
	features: [
		{
			icon: speed,
			title: "Performance",
			desc: [
				"Other Pages gets build with every request",
				"Paperplane Pages only when it matters."
			]
		},
		{
			icon: content,
			title: "Content",
			desc: [
				"Not the system decides the form of your content. You should.",
				"Paperplane Pages makes you own your content.",
				"With your own content management system."
			]
		},
	],
}

const de = {
	label: "Statische Seiten",
	headline: "Warum Paperplane Pages?",
	desc: [
		"Das Internet von heute ist langsam.",
		"Und Zeit kostbar.",
		"Paperplane Pages sind die nächste Stufe für Webseiten.",
		"Einfach, schneller und sicherer."
	],
	features: [
		{
			icon: speed,
			title: "Performance",
			desc: [
				"Andere Seiten werden mit jeder Anfrage neu gebaut und geladen.",
				"Paperplane Pages nur, wenn es darauf ankommt."
			]
		},
		{
			icon: content,
			title: "Content",
			desc: [
				"Nicht das System entscheidet über die Form Ihres Inhalts. Sie sollten es tun.",
				"Paperplane Pages macht Ihren Inhalt zu Ihrem Eigentum.",
				"Mit Ihrem eigenen Content-Management-System."
			]
		},
	],
}


Why.propTypes = { lang: PropTypes.string.isRequired }

export default Why;
