import React from 'react'
import hero from "../../images/hero.svg";

import PropTypes from "prop-types";

const Hero = ({lang}) => {

    const content = lang === "de" ? de : en;

    return (
      <section className="bg-blue-600 text-white">
        <div className="md:flex md:flex-row-reverse">
  
          <div className="md:w-1/2">
            <img className="w-full object-cover object-center mb-16" src={hero} alt=""/>
          </div>
  
          <div className="md:w-1/2">
            <div className="pb-8">
              <h1 className="leading-tight md:text-5xl md:leading-none md:mb-4 md:mt-32" id="sample-markdown">
                <span className="text-blue-200">{content.headline}</span> <br/>{content.subHeadline}</h1>
              <div className="mb-12 text-blue-100 md:text-xl">
              <p>
                {content.desc1}<br/>
                {content.desc2}<br/>
                {content.desc3}<br/>
              </p>
              </div>
              <a className="w-32 px-6 py-3 font-semibold bg-blue-200 text-blue-800 rounded-lg uppercase tracking-wide text-sm" href="#">{content.callToAction}</a>
            </div>
          </div>
  
        </div>
      </section>
    );
}

const en = {
  headline: "You need to be fast.",
  subHeadline: "But your site faster",
  desc1: "Forget about Wordpress.",
  desc2: "Forget about long loading times.",
  desc3: "We create sites fast and light, like a paperplane.",
  callToAction: "Contact Us"
}

const de = {
  headline: "Sie müssen schnell sein",
  subHeadline: "Ihre Seite aber schneller",
  desc1: "Vergessen Sie Wordpress.",
  desc2: "Vergessen Sie lange Ladezeiten.",
  desc3: "Wir erstellen Seiten, leicht und schnell wie ein Papierflugzeug.",
  callToAction: "Kontakt aufnehmen"
}

Hero.propTypes = { lang: PropTypes.string.isRequired }

export default Hero;
