import React from 'react'
import speed2 from "../../images/speed2.svg"
import search from "../../images/search.svg"
import cloud from "../../images/cloud.svg"
import euro from "../../images/euro.svg"
import PropTypes from "prop-types";

const Performance = ({lang}) => {

  const content = lang === "de" ? de : en;

    return(
      <section className="bg-blue-600 text-white">
        <div>
  
          <div className="pb-4">
            <div className="label text-blue-300">{content.label}</div>
            <h2 id="faster-than-the-rest">{content.title}</h2>
            <p className="text-blue-100 border-l-4 border-blue-300 pl-4">
              { content.desc.map(item => <>{item}<br /></>) }
            </p>
          </div>
  
          <div className="md:flex md:flex-wrap md:-mx-4">
            {content.features.map(feature =>(
              <div key={feature.title} className="md:w-1/2 lg:w-1/4 md:px-4 md:pb-8">
                <div className="card bg-blue-200 border-blue-300 h-full">
                  <img className="mb-4" src={feature.icon} alt=""/>
                  <h3 className="text-blue-900" id="faster-websites">{feature.title}</h3>
                  <p className="text-blue-800 font-normal">
                  { feature.desc.map(item => <>{item}<br /></>) }
                  </p>
                </div>
              </div>
            ))}

          </div>
  
        </div>
      </section>
    );
  }

  Performance.propTypes = { lang: PropTypes.string.isRequired };

const en = {
  label: "Performance",
  title: "Faster than the rest",
  desc: [
    "Do you know who loves fast sites?",
    "Users and Google.",
    "Kill to birds"
  ],
  features: [
    {
      title: "Faster Websites",
      icon: speed2,
      desc: ["Dont let your users wait.", "The attention span is short.", "Let's use it right."]
    },
    {
      title: "SEO",
      icon: search,
      desc: ["Get ranked higher.", "Google prefers fast loading pages."]
    },
    {
      title: "CDN",
      icon: cloud,
      desc: ["Hosted in a Content Delivery Network (CDN).", "Users get the site from the closest server."]
    },
    {
      title: "Cheaper Cost",
      icon: euro,
      desc: ["Safe yourself hosting solutions.", "You only pay, what you really need."]
    },
  ],
}

const de = {
  label: "Performance",
  title: "Schneller als der Rest",
  desc: [
    "Wissen Sie wer schnelle Seiten liebt?",
    "Nutzer und Google.",
    "Schlagen Sie zwei Fliegen mit einer Klappe."
  ],
  features: [
    {
      title: "Schnellere Website",
      icon: speed2,
      desc: ["Lassen Sie den Nutzer nicht warten.", "Die Aufmerksamkeitsspanne ist kurz. Nutzen wir sie."]
    },
    {
      title: "SEO",
      icon: search,
      desc: ["Ranken Sie besser bei Suchmaschinen.", "Google liebt schnelle Ladezeiten."]
    },
    {
      title: "CDN",
      icon: cloud,
      desc: ["Gehostet in einem Content Delivery Network (CDN).", "Benutzer erhalten die Site vom nächstgelegenen Server."]
    },
    {
      title: "Günstigere Kosten",
      icon: euro,
      desc: ["Sparen Sie sich Hosting-Lösungen.", "Sie bezahlen nur, was Sie wirklich brauchen."]
    },
  ],
}

export default Performance;
